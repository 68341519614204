import { DestroyRef, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { IUser, IUserLoginReq, IUserLoginRes, IUserLoginSuccess } from '../interfaces/user.interface';
import { HttpClient, HttpContext } from '@angular/common/http';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment.development';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IS_PUBLIC } from '../interceptors/auth.interceptor';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private readonly http = inject(HttpClient);
	private readonly router = inject(Router);
	private readonly jwtHelper = inject(JwtHelperService);
	private readonly destroyRef = inject(DestroyRef);
	private readonly CONTEXT = { context: new HttpContext().set(IS_PUBLIC, true) };
	private readonly TOKEN_EXPIRY_THRESHOLD_MINUTES = 5;

	get user(): WritableSignal<UserActivation | null> {
		const token = localStorage.getItem('token');
		return signal(token ? this.jwtHelper.decodeToken(token) : null);
	}

	isAuthenticated(): boolean {
		return !this.jwtHelper.isTokenExpired();
	}


	login(body: IUserLoginReq): Observable<IUserLoginRes> {
		return this.http.post<IUserLoginRes>(`${environment.apiUrl}sign-in`, body, this.CONTEXT)
			.pipe(
				catchError(error => {
					if (error.status === 400) {
						// Handle invalid credentials
						console.log('Invalid credentials');
					}
					return of();
				}),
				tap(data => {
					const loginSuccessData = data as IUserLoginSuccess;
					this.storeTokens(loginSuccessData);
					this.scheduleTokenRefresh(loginSuccessData.token);
					this.router.navigate(['/']);
				})
			);
	}

	logout(): void {
		// if you don't have any backend route to invalidate the refresh token
		// then just remove localStorage items and redirect to login route
		const refresh_token = localStorage.getItem('refresh_token');
		this.http.post<IUserLoginRes>(`${environment.apiUrl}token/invalidate`, { refresh_token }, this.CONTEXT)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				localStorage.removeItem('token');
				localStorage.removeItem('refresh_token');
				this.router.navigate(['/login']);
			});
	}

	storeTokens(data: IUserLoginSuccess): void {
		localStorage.setItem('token', data.token);
		//localStorage.setItem('refresh_token', data.refresh_token);
	}

	refreshToken(): Observable<IUserLoginRes | null> {
		const refresh_token = localStorage.getItem('refresh_token');
		if (!refresh_token) {
			return of();
		}

		return this.http.post<IUserLoginRes>(
			`${environment.apiUrl}/token/refresh`, { refresh_token }, this.CONTEXT)
			.pipe(
				catchError(() => of()),
				tap(data => {
					const loginSuccessData = data as IUserLoginSuccess;
					this.storeTokens(loginSuccessData);
					this.scheduleTokenRefresh(loginSuccessData.token);
				})
			);
	}

	scheduleTokenRefresh(token: string): void {
		const expirationTime = this.jwtHelper.getTokenExpirationDate(token)?.getTime();
		const refreshTime = expirationTime ? expirationTime - this.TOKEN_EXPIRY_THRESHOLD_MINUTES * 60 * 1000 : Date.now();
		const refreshInterval = refreshTime - Date.now();

		if (refreshInterval > 0) {
			setTimeout(() => {
				this.refreshToken()
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe();
			}, refreshInterval);
		}
	}

}
